import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserData } from '../store/Auth/selectors';
import { checkSession } from '../store/Auth';
import { useAppDispatch } from '../store';
import Loading from '../components/Loading';
import { NotAuthRoutes } from './NotAuthRoutes';
import { AdminRoute, ControlPanelRoute } from './AuthRoutes';
import { isCPAppType } from '../utils/http';
import PasswordSetCard from './Auth/components/PasswordSetCard';

const Pages = () => {
    const user = useSelector(getCurrentUserData);
    const dispatch = useAppDispatch();
    const [isCheckingSession, setIsCheckingSession] = React.useState(true);

    useEffect(() => {
        if (user.userId === null && !user.waitResponse) {
            dispatch(checkSession()).finally(() => {
                setIsCheckingSession(false);
            });
        } else {
            setIsCheckingSession(false);
        }
    }, [dispatch]);
    const getAuthRoutes = () => {
        if (user.neededPasswordUpdate) {
            return <PasswordSetCard />;
        }
        if (isCPAppType) {
            return <ControlPanelRoute />;
        }
        return <AdminRoute />;
    };

    const getContentToUser = () => {
        if (user.waitResponse) {
            return <Loading />;
        }
        switch (isCheckingSession || user.userId) {
            case null: {
                return <NotAuthRoutes />;
            }
            default: {
                return getAuthRoutes();
            }
        }
    };

    return <>{getContentToUser()}</>;
};

export default Pages;
